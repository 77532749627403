<template>
    <div class="holography-list-cell">
        <div class="holography-list-cell-left">
            <date-label :title="dateStr"></date-label>
            <img class="holography-list-cell-left-circle" :src="circleIcon">
        </div>
        <div class="holography-list-cell-right">
            <div class="holography-list-cell-right-unit" style="width: 18%">
                <unit :itemArray="caseArray" @onItemClick="onItemClick"></unit>
            </div>
            <div class="holography-list-cell-right-unit" style="width: 18%">
                <unit :itemArray="pressurePrescriptionArray" @onItemClick="onItemClick"></unit>
            </div>
            <div class="holography-list-cell-right-unit" style="width: 14%">
                <unit v-if="model.isReport" :itemArray="reportArray" @onItemClick="onItemClick"></unit>
            </div>
            <div class="holography-list-cell-right-unit" style="width: 16%">
                <unit :itemArray="mediaArray" @onItemClick="onItemClick"></unit>
            </div>
            <div class="holography-list-cell-right-unit" style="width: 16%">
                <unit :itemArray="questionnaireArray" @onItemClick="onItemClick"></unit>
            </div>
            <div class="holography-list-cell-right-unit" style="width: 18%">
                <status-label class="holography-list-cell-right-unit-status" :isPublished="isPublished"
                    @onClick="onStatusLabelClick"></status-label>
                <p class="holography-list-cell-right-unit-operator-name">操作人：{{ operatorName }}</p>
            </div>
        </div>
        <!-- 病例查看 -->
        <case-detail-alert v-if="isShowCaseDetailAlert" v-model="isShowCaseDetailAlert" :model="model"
            @onEdit="onEditCase"></case-detail-alert>
        <!-- 参数查看 -->
        <press-prescription-alert v-if="isShowPressPrescriptionAlert" v-model="isShowPressPrescriptionAlert"
            :model="model"></press-prescription-alert>
        <!-- 报告查看 -->
        <el-drawer title="呼吸机报告" :visible.sync="isShowReportDrawer" destroy-on-close size="665px">
            <drawer-content :model="model"></drawer-content>
        </el-drawer>
        <!-- 检查报告查看 -->
        <exam-report-alert v-if="isShowExamReportAlert" v-model="isShowExamReportAlert"
            :model="selectedExamReport"></exam-report-alert>
        <!-- 图片查看 -->
        <template v-if="imageList[previewPhotoIndex]?.imageUrl">
            <image-viewer :initial-index="previewPhotoIndex" v-if="isShowPhotoPreviewAlert"
                :on-close="(e) => isShowPhotoPreviewAlert = false" :url-list="imageList" :zIndex="2200">
            </image-viewer>
        </template>
        <!-- 问卷查看 -->
        <questionnaire-alert v-if="isShowQuestionnaireAlert" v-model="isShowQuestionnaireAlert"
            :selectedQuestionnaireType="selectedQuestionnaireType" :caseModel="model"></questionnaire-alert>
    </div>
</template>

<script>
import DateLabel from "@c/user-main/holography/holography-list-cell/holography-list-cell-date-label.vue";
import StatusLabel from "@c/user-main/holography/holography-list-cell/holography-list-cell-status-label.vue";
import Unit from "@c/user-main/holography/holography-list-cell/holography-list-cell-unit.vue";
import CaseDetailAlert from "@c/common/alert/panel-alert/panel-alert-case-detail/panel-alert-case-detail.vue";
import DrawerContent from "@c/user-main/holography/holography-breath-drawer-content/holography-breath-drawer-content.vue";
import ExamReportAlert from "@c/common/alert/panel-alert/panel-alert-exam-report/panel-alert-exam-report.vue";
import PressPrescriptionAlert from "@c/common/alert/panel-alert/panel-alert-press-prescription/panel-alert-press-prescription.vue";
import { DateTool } from "@js/date-tool.js";
import { Drawer } from "element-ui";
import QuestionnaireAlert from "@c/common/pop-view/pop-view-holography-questionnaire/index.vue";
import ImageViewer from '@c/common/image-viewer.vue';
import { ParameterComposeTool } from "@js/parameter-compose-tool.js";
import { getPathExtension, downFile } from "@js/file-tool.js";
export default {
    components: {
        DateLabel,
        StatusLabel,
        Unit,
        CaseDetailAlert,
        DrawerContent,
        PressPrescriptionAlert,
        elDrawer: Drawer,
        QuestionnaireAlert,
        ImageViewer,
        ExamReportAlert
    },

    props: {
        model: Object
    },

    data() {
        return {
            circleIcon: require("@imgs/holography-list-circle.png"),
            isShowCaseDetailAlert: false,
            isShowPressPrescriptionAlert: false,
            isShowReportDrawer: false,
            //exameReportAlert
            isShowExamReportAlert: false,
            selectedExamReport: undefined,
            //previewAlert
            isShowPhotoPreviewAlert: false,
            previewPhotoIndex: undefined,
            //questionnaireAlert
            isShowQuestionnaireAlert: false,
            selectedQuestionnaireType: String

        }
    },

    computed: {
        dateStr() {
            return placeholderText(DateTool.timeStrToStr(this.model.gmtModify, "MM月DD日"))
        },

        isPublished() {
            return this.model.status
        },

        operatorName() {
            return placeholderText(this.model.operatorName)
        },

        caseArray() {
            return [{
                name: "case",
                title: "病史病例"
            }]
        },

        pressurePrescriptionArray() {
            if (!this.model.parameter) { return }
            const parameterComposeTool = new ParameterComposeTool(this.model.parameter)
            const pressurePrescriptionArray = parameterComposeTool.composeHolographyListParamArray()
            return pressurePrescriptionArray.map((item) => {
                return {
                    name: "pressurePrescription",
                    title: `${item.title}：${item.info}`
                }
            })
        },

        reportArray() {
            if (this.model.isReport) {
                const rawCompliance = (this.model.compliance * 100).toFixed(0) //0需要展示
                const compliance = placeholderText(rawCompliance, '%').replace(' ', '')
                return [{
                    name: "report",
                    title: (`依从性：${compliance}`)
                }, {
                    name: "report",
                    title: "查看报告"
                }]
            } else {
                return []
            }
        },

        mediaArray() {
            const dataArray = []
            for (const item of this.model.inspectionReportFile || []) {
                for (const fileItem of item.file) {
                    if (fileItem.url) {
                        const extensionName = getPathExtension(fileItem.url)
                        const isPhoto = extensionName == 'jpg' || extensionName == 'jpeg' || extensionName == 'png'
                        dataArray.push({
                            name: isPhoto ? 'photo' : "file",
                            title: item.categoryName,
                            content: fileItem
                        })
                    }
                }
            }
            for (const item of this.model.sleepReportFile || []) {
                for (const fileItem of item.file) {
                    const extensionName = getPathExtension(fileItem.url)
                    const isPhoto = extensionName == 'jpg' || extensionName == 'jpeg' || extensionName == 'png'
                    dataArray.push({
                        name: isPhoto ? 'photo' : "file",
                        title: item.categoryName,
                        content: fileItem
                    })
                }
            }
            for (const item of this.model.sleepReport || []) {
                dataArray.push({
                    name: "data",
                    title: "睡眠报告",
                    content: item
                })
            }
            return dataArray
        },

        questionnaireArray() {
            if (this.model.isQuestionnaire) {
                return [{
                    name: "questionnaire",
                    title: "GAD-7"
                }, {
                    name: "questionnaire",
                    title: "PHQ-9"
                }]
            } else {
                return []
            }

        },
        imageList() {
            return this.mediaArray.filter((item) => {
                return item.name == 'photo'
            }).map((item) => {
                return {
                    name: item.title,
                    imageUrl: item.content.url
                }
            }) ?? []
        }
    },

    methods: {
        onEditCase() {
            this.isShowCaseDetailAlert = false
            this.$emit("onEditCase", this.model)
        },

        onItemClick(item) {
            switch (item.name) {
                // 诊断
                case "case":
                    this.isShowCaseDetailAlert = true
                    break
                // 压力处方
                case "pressurePrescription":
                    this.isShowPressPrescriptionAlert = true
                    break;
                // 使用报告
                case "report":
                    this.isShowReportDrawer = true
                    break
                // 检验检查
                case "photo":
                    this.previewPhotoIndex = this.imageList.findIndex(el => el.imageUrl == item.content.url)
                    this.isShowPhotoPreviewAlert = true
                    break
                case "file":
                    downFile(item.content.url, item.content.name)
                    break
                case "data":
                    this.selectedExamReport = item.content
                    this.isShowExamReportAlert = true
                    break
                // 评估
                case "questionnaire":
                    this.isShowQuestionnaireAlert = true
                    this.selectedQuestionnaireType = item.title
                    break
                default:
                    break;
            }
        },

        onStatusLabelClick() {
            this.isShowCaseDetailAlert = true
        }

    }
}
</script>

<style lang="scss" scoped>
/deep/.el-drawer__header {
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.holography-list-cell {
    display: flex;

    &-left {
        position: relative;
        width: 9%;
        box-sizing: border-box;
        padding-top: 15px;
        display: flex;
        justify-content: center;

        &-date {
            color: white;
            background-color: #2E6BE6;
            font-size: 15px;
            border-radius: 4px;
            width: 92px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-circle {
            position: absolute;
            top: 21px;
            right: -7px;
            width: 14px;
            height: 14px;
        }
    }

    &-right {
        flex: 1;
        border-bottom: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        min-height: 66px;
        box-sizing: border-box;
        padding-left: 34px;
        padding-right: 115px;
        display: flex;

        &-unit {
            &-status {
                margin-top: 17px;
            }

            &-operator-name {
                color: #ABC7FF;
                font-size: 16px;
                line-height: 22px;
                margin-top: 4px;
                margin-bottom: 17px;
            }
        }

    }
}
</style>