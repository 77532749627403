<template>
    <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
        <div class="cell">
            <p style="width: 25%;">{{ dateStr }}</p>
            <p style="width: 15%;">{{ AHIStr }}</p>
            <p style="width: 20%;">{{ leakStr }}</p>
            <p style="width: 20%;">{{ timeStr }}</p>
            <div style="width: 20%;">
                <button class="cell-check-button" @click="onCheck">查看</button>
            </div>
        </div>
    </list-cell>
</template>
  
<script>
import ListCell from "@c/common/paging-list/list-cell.vue";
import { DateTool } from "@js/date-tool.js";

export default {
    components: {
        ListCell,
    },

    props: {
        model: Object,
        isSelected: Boolean,
    },

    computed: {
        dateStr() {
            return placeholderText(this.model.reportDate)
        },

        AHIStr() {
            return placeholderText(this.model.ahi)
        },

        leakStr() {
            return placeholderText(this.model.leakage)
        },

        timeStr() {
            return DateTool.formatSeconds(this.model.longTime)
        }

    },

    methods: {
        onSelectClick() {
            this.$emit("onSelectClick", this.model);
        },

        onCheck() {
            this.$emit("onCheck", this.model);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.cell {
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    >p {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-check-button {
        color: #1677FF;
        background-color: #E6F4FF;
        border: 1px solid #BAE0FF;
        width: 55px;
        height: 24px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>