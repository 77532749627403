<template>
    <panel-alert class="panel-alert-case-detail" title="病史病历" width="1027px"
        @onCloseClick="onCloseClick">
        <template v-slot:pre-title>
            <button v-if="isTemporarySaved" class="panel-alert-case-detail-edit" @click="onEdit">编辑</button>
        </template>
        <template v-slot:content>
            <detail-view :model="model" :isTemporarySaved="isTemporarySaved" ></detail-view>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import DetailView from "@c/common/alert/panel-alert/panel-alert-case-detail/detail-view.vue";
export default {
    components: {
        PanelAlert,
        DetailView
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        model: Object,
        isShow: Boolean
    },

    computed:{
        isTemporarySaved() {
            return !this.model.status
        },
    },

    methods: {
        onCloseClick() {
            this.$emit("onIsShowChanged", false);
        },

        onEdit(){
            this.$emit("onEdit")
        }
    },
};
</script>
  
<style lang="scss" scoped>
.panel-alert-case-detail {

    &-edit {
        color: #2E6BE6;
        border: 1px solid #2E6BE6;
        border-radius: 6px;
        width: 63px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-left: 24px;
    }

    /deep/.panel-alert-top-left-title{
        position: absolute;
        left: 450px;
    }
}
</style>