<template>
    <div class="content-item">
        <p class="content-item-title">{{ titleStr }}</p>
        <p class="content-item-time">评估时间：{{ dateStr }}</p>
        <div class="content-item-content">
            <p class="content-item-content-title">评估结果（得分越低越好）</p>
            <div class="content-item-content-subtitle">
                <div class="content-item-content-subtitle-item" v-for="_, index in new Array(2)" :key="index">
                    <p>纬度</p>
                    <p>得分</p>
                </div>
            </div>
            <div class="content-item-content-score">
                <div class="content-item-content-score-item" v-for="item, index in  tableArray " :key="index"
                    :style="{ width: index % 2 == 0 ? '53%' : '47%', paddingRight: index % 2 == 0 ? '6%' : '0%' }">
                    <p>{{ item.content }}</p>
                    <p>{{ item.score }}</p>
                </div>
            </div>
            <bar-chart :model="model"></bar-chart>
            <div class="content-item-content-result">
                <div class="content-item-content-result-item">
                    <p class="content-item-content-result-item-title">结果：</p>
                    <p class="content-item-content-result-item-info">{{ resultStr }}</p>
                </div>
                <div class="content-item-content-result-item">
                    <p class="content-item-content-result-item-title">建议：</p>
                    <p class="content-item-content-result-item-info">{{ suggestionStr }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
import { convertLatitudeStr } from "./tool";
import BarChart from "./bar-chart.vue";
export default {
    components: {
        BarChart
    },

    props: {
        model: Object
    },

    computed: {
        titleStr() {
            return placeholderText(this.model?.title)
        },

        dateStr() {
            return placeholderText(DateTool.timeStrToStr(this.model?.gmtCreate))
        },

        tableArray() {
            let array = []
            const model = this.model
            if (model) {
                const totalItem = { content: `总分`, score: `${model.assessmentScore}（${model.minScore}~${model.maxScore}）` }
                array.push(totalItem)
                for (const item of model.userAnswerList) {
                    const resultItem = { content: `${convertLatitudeStr(item.latitude)} ${item.problemTitle}`, score: `${item.answerScore}（${item.minScore}~${item.maxScore}）` }
                    array.push(resultItem)
                }
            }
            return array
        },

        resultStr() {
            return placeholderText(this.model?.assessmentResult)
        },

        suggestionStr() {
            return placeholderText(this.model?.assessmentProposal)
        },

    }
}
</script>

<style lang="scss" scoped>
.content-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        margin-top: 20px;
    }

    &-time {
        font-size: 18px;
        line-height: 25px;
        margin-top: 12px;
    }

    &-content {
        width: 100%;
        box-sizing: border-box;
        padding: 0 36px;
        margin-top: 12px;


        &-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
            border-bottom: 1px solid #E8E8E8;
        }

        &-subtitle {
            border-bottom: 1px solid #E8E8E8;
            display: flex;
            justify-content: space-between;

            &-item {
                font-size: 16px;
                font-weight: bold;
                line-height: 40px;
                width: 47%;
                display: flex;
                justify-content: space-between;
            }
        }

        &-score {
            font-size: 16px;
            line-height: 22px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;


            &-item {
                border-bottom: 1px solid #E8E8E8;
                min-height: 40px;
                padding: 5px 0px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;

                :first-child {
                    max-width: 78%;
                }
            }
        }

        &-result {
            margin-top: 12px;
            margin-bottom: 20px;

            &-item {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 12px;
                display: flex;

                &-title {
                    font-weight: bold;
                    width: 54px;
                }

                &-info {
                    flex: 1;
                }
            }


        }
    }
}
</style>