<template>
    <div class="parameter-view">
        <div class="parameter-view-content">
            <div class="parameter-view-content-item" :class="[index % 2 == 0 ? 'left' : 'right']"
                v-for="(item, index) of dataArray" :key="index">
                <p>{{ item.title }}</p>
                ：
                <p>{{ item.info }}</p>
            </div>
        </div>
        <div v-if="!model.isInitial" class="parameter-view-next" @click="onNext">
            <button class="parameter-view-next-button">上一次压力处方</button>
            <div>
                <img v-if="isShowPreviousPrescription" class="parameter-view-next-arrow" :src="arrowUpIcon">
                <img v-else class="parameter-view-next-arrow" :src="arrowDownIcon">
            </div>
        </div>
        <div v-if="isShowPreviousPrescription" class="parameter-view-content last-time">
            <div class="parameter-view-content-item" :class="[index % 2 == 0 ? 'left' : 'right']"
                v-for="(item, index) of previousDataArray" :key="index">
                <p>{{ item.title }}</p>
                ：
                <p>{{ item.info }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ParameterComposeTool } from "@js/parameter-compose-tool.js";
export default {
    props: {
        model: Object
    },

    data() {
        return {
            arrowUpIcon: require("@imgs/holography-pressure-prescription-alert-arrow-up.png"),
            arrowDownIcon: require("@imgs/holography-pressure-prescription-alert-arrow-down.png"),
            isShowPreviousPrescription: false,
            dataArray: [],
            previousDataArray: []
        }
    },

    async mounted() {
        const parameterComposeTool = new ParameterComposeTool(this.model.parameter)
        this.dataArray = await parameterComposeTool.composeHolographyParamArray()
    },

    methods: {
        onNext() {
            if (!this.previousDataArray.length) {
                this.getPreviousDetail()
            } else {
                this.isShowPreviousPrescription = !this.isShowPreviousPrescription
            }
        },

        async getPreviousDetail() {
            try {
                const param = {
                    id: this.model.id,
                    patientId: this.model.patientId
                }
                const previousModel = await this.$api.getPreviousCaseDetail(param)
                if (previousModel) {
                    const parameterComposeTool = new ParameterComposeTool(previousModel.parameter)
                    this.previousDataArray = await parameterComposeTool.composeHolographyParamArray()
                    this.isShowPreviousPrescription = true
                } else {
                    throw "不存在上一次压力处方"
                }
            } catch (error) {
                this.$toast.showRed(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.parameter-view {

    &-content {
        display: flex;
        flex-wrap: wrap;

        &-item {
            display: flex;
            font-size: 17px;
            line-height: 25px;
            width: 50%;
            margin-bottom: 20px;
        }
    }

    &-next {
        display: flex;
        align-items: center;
        /* height: 46px; */
        // border-bottom: 1px solid #E8E8E8;

        &-button {
            color: #2779FF;
            font-size: 18px;
            line-height: 25px;
        }

        &-arrow {
            width: 19px;
            height: 19px;
            margin-left: 10px;
        }
    }

}

.left {
    width: 330px;

}

.right {
    flex: 1;
}

.last-time {
    border-top: 1px solid #E8E8E8;
    margin-top: 21px;
    box-sizing: border-box;
    padding-top: 20px;
}
</style>