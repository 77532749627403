<template>
    <div class="holography-list-cell-unit">
        <button class="holography-list-cell-unit-item" v-for="(item, index) in itemArray" :key="index"
            @click="onItemClick(item)">{{ item.title }}
        </button>
    </div>
</template>

<script>
export default {

    props: {
        itemArray: Array
    },

    methods: {
        onItemClick(item) {
            this.$emit("onItemClick", item)
        }
    }

}
</script>

<style lang="scss" scoped>
.holography-list-cell-unit {
    box-sizing: border-box;
    padding: 17px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
        color: #2E6BE6;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }
}
</style>