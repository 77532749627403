<template>
    <panel-alert class="panel-alert-data-report" title="睡眠报告" width="496px" @onMaskClick="onMaskClick"
        @onCloseClick="onCloseClick">
        <template v-slot:content>
            <div class="panel-alert-data-report-content">
                <div class="panel-alert-data-report-content-column">
                    <unit title="日期" :value="reportTime"></unit>
                    <unit title="ODI3" :value="odi"></unit>
                    <unit title="HI" :value="hi"></unit>
                    <unit title="平均血氧" :value="spo2avg"></unit>
                </div>
                <div class="panel-alert-data-report-content-column">
                    <unit title="AHI" :value="ahi"></unit>
                    <unit title="AI" :value="ai"></unit>
                    <unit title="最低血氧" :value="spo2min"></unit>
                    <button v-if="isShowLink" class="panel-alert-data-report-content-link" @click="onLinkClick">
                        <img class="panel-alert-data-report-content-link-icon" :src="linkIcon">
                        <p class="panel-alert-data-report-content-link-title">睡眠报告链接</p>
                    </button>
                </div>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import Unit from "./unit.vue"
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        PanelAlert,
        Unit
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        model: Object,
        isShow: Boolean
    },

    data() {
        return {
            linkIcon: require("@imgs/holography-exam-report-alert-link.png")
        }
    },

    computed: {
        reportTime() {
            return placeholderText(DateTool.timeStrToStr(this.model.reportTime, 'YYYY年MM月DD日'))
        },

        ahi() {
            return placeholderText(this.model.ahi, '次/小时')
        },

        odi() {
            return placeholderText(this.model.odi, '次/小时')
        },

        ai() {
            return placeholderText(this.model.ai, '次/小时')
        },

        hi() {
            return placeholderText(this.model.hi, '次/小时')
        },

        spo2min() {
            return placeholderText(this.model.spo2min, '%')
        },

        spo2avg() {
            return placeholderText(this.model.spo2avg, '%')
        },

        isShowLink() {
            return this.model.reportId
        }
    },

    methods: {
        onMaskClick() {
            this.$emit("onIsShowChanged", false);
        },

        onCloseClick() {
            this.$emit("onIsShowChanged", false);
        },

        onLinkClick() {
            const patientName = this.model.userName;
            const createTimestamp = DateTool.timeStrToTimestamp(this.model.reportTime)
            const reportID = this.model.reportId;
            const reportURL = `https://cloud.bajiesleep.com/report?userName=${patientName}&reportURL=${reportID}&reportCreateTime=${createTimestamp}`;
            window.open(reportURL, "_blank");
        }
    },
};
</script>
  
<style lang="scss" scoped>
/deep/.panel-alert-content {
    padding: 9px 29px 15px 26px !important;
}

.panel-alert-data-report {
    &-content {
        display: flex;
        justify-content: space-between;

        &-column {
            // width: 50%;
            display: flex;
            flex-direction: column;
        }

        &-link {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &-icon {
                width: 17px;
                height: 17px;

            }

            &-title {
                color: #5889EB;
                font-size: 14px;
                font-weight: 500;
                margin-left: 6px;
            }
        }
    }
}
</style>