<template>
    <div class="holography-list-footer">
        <div class="holography-list-footer-content">
            <p class="holography-list-footer-content-empty" v-if="isEmpty">暂无数据</p>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        isEmpty: Boolean
    }

}
</script>

<style lang="scss" scoped>
.holography-list-footer {
    box-sizing: border-box;
    padding-left: 9%;

    &-content {
        border-left: 1px solid #E8E8E8;
        height: 100%;

        &-empty {
            font-size: 28px;
            width: 100%;
            padding-top: 150px;
            text-align: center;
        }
    }

}
</style>