import { findKey } from "@js/object-tool.js";
import { ParameterComposeTool } from "@js/parameter-compose-tool.js";
export class CaseDisplayModel {
    constructor(model) {
        this.model = model
    }

    complaints() {
        const rawArray = [this.model?.complaints?.symptom, this.model?.complaints?.diseaseDuration]
        const contentStr = this.composeContent(rawArray, '；')
        return placeholderText(contentStr)
    }

    currentHistory() {
        const sleep = this.composeMultipleOptionStr(this.model?.medicalHistory?.sleepProblems, SLEEP_DATA_ARRAY)
        const eat = this.composeMultipleOptionStr(this.model?.medicalHistory?.dietaryProblems, EAT_DATA_ARRAY)
        const emotionLow = this.composeMultipleOptionStr(this.model?.medicalHistory?.downcast, EMOTION_LOW_DATA_ARRAY)
        const emotionAnxious = this.composeMultipleOptionStr(this.model?.medicalHistory?.anxiety, EMOTION_ANXIOUS_DATA_ARRAY)
        const emotionHigh = this.composeMultipleOptionStr(this.model?.medicalHistory?.emotionalHigh, EMOTION_HIGH_DATA_ARRAY)
        const depress = this.composeMultipleOptionStr(this.model?.medicalHistory?.otherDepressive, DEPRESS_DATA_ARRAY)
        const anxious = this.composeMultipleOptionStr(this.model?.medicalHistory?.otherAnxiety, ANXIOUS_DATA_ARRAY)
        const crazy = this.composeMultipleOptionStr(this.model?.medicalHistory?.otherManic, CRAZY_DATA_ARRAY)
        const social = this.composeMultipleOptionStr(this.model?.medicalHistory?.social, SOCIAL_DATA_ARRAY)
        const risk = this.composeMultipleOptionStr(this.model?.medicalHistory?.riskAssessment, RISK_DATA_ARRAY)
        //content
        const rawArray = [sleep, eat, emotionLow, emotionAnxious, emotionHigh, depress, anxious, crazy, social, risk]
        const contentStr = this.composeContent(rawArray, '；')
        return placeholderText(contentStr)
    }


    pastHistory() {
        const heart = this.composeMultipleOptionStr(this.model?.antecedentHistory?.cardiovascular, HEART_DATA_ARRAY)
        const endocrine = this.composeMultipleOptionStr(this.model?.antecedentHistory?.endocrine, ENDOCRINE_DATA_ARRAY)
        const breath = this.composeMultipleOptionStr(this.model?.antecedentHistory?.respiratory, BREATH_DATA_ARRAY)
        const tumor = this.composeMultipleOptionStr(this.model?.antecedentHistory?.tumor, TUMOR_DATA_ARRAY)
        const kidneyDisease = this.model?.antecedentHistory?.liverKidneyAbnormalities
        const infectionDisease = this.model?.antecedentHistory?.pestilence
        const surgery = this.model?.antecedentHistory?.traumaSurgery
        const allergy = this.model?.antecedentHistory?.foodDrugAllergies
        //content
        const rawArray = [heart, endocrine, breath, tumor, kidneyDisease, infectionDisease, surgery, allergy]
        const contentStr = this.composeContent(rawArray, '；')
        return placeholderText(contentStr)
    }

    personalHistory() {
        //smoke
        const smokeHistory = findKey(SMOKE_HISTORY_ARRAY, this.model?.personalHistory?.smokingHistory)
        const rawSmokeAge = findKey(SMOKE_AGE_ARRAY, this.model?.personalHistory?.smokingAge)
        const smokeAge = rawSmokeAge ? `烟龄${rawSmokeAge}` : ""
        const rawSmokeNumber = findKey(SMOKE_NUMBER_ARRAY, this.model?.personalHistory?.numberPerDay)
        const smokeNumber = rawSmokeNumber ? `每天${rawSmokeNumber}` : ""
        const rawSmokeArray = [smokeHistory, smokeAge, smokeNumber]
        const smokeStr = this.composeContent(rawSmokeArray, '，')
        //alcohol
        const alcoholHistory = findKey(ALCOHOL_HISTORY_ARRAY, this.model?.personalHistory?.drinkingHistory)
        const rawAlcoholAge = findKey(ALCOHOL_AGE_ARRAY, this.model?.personalHistory?.drinkingAge)
        const alcoholAge = rawAlcoholAge ? `酒龄${rawAlcoholAge}` : ""
        const rawAlcoholType = findKey(ALCOHOL_TYPE_ARRAY, this.model?.personalHistory?.mainLiquors)
        const alcoholType = rawAlcoholType == '以上均有' ? '白酒、啤酒、红酒' : rawAlcoholType
        const rawAlcoholQuantity = findKey(ALCOHOL_QUANTITY_ARRAY, this.model?.personalHistory?.drinkingAmount)
        const alcoholQuantity = rawAlcoholQuantity ? `${rawAlcoholQuantity}饮用` : ''
        const rawAlcoholArray = [alcoholHistory, alcoholAge, alcoholType, alcoholQuantity]
        const alcoholStr = this.composeContent(rawAlcoholArray, '，')
        //drink
        const drink = findKey(DRINK_ARRAY, this.model?.personalHistory?.coffeeAndStrongTea)
        //drug
        const drug = this.model?.personalHistory?.psychiatricDrugs
        //content
        const rawArray = [smokeStr, alcoholStr, drink, drug]
        const contentStr = this.composeContent(rawArray, '；')
        return placeholderText(contentStr)
    }

    physicalExamination() {
        const rawHeight = this.model?.characterization?.height
        const height = rawHeight ? `身高${rawHeight}cm` : ""
        const rawWeight = this.model?.characterization?.weight
        const weight = rawWeight ? `体重${rawWeight}kg` : ""
        const rawNeck = this.model?.characterization?.neckband
        const neck = rawNeck ? `颈围${rawNeck}cm` : ""
        const rawBloodPressure = this.model?.characterization?.bloodPressure
        const bloodPressure = rawBloodPressure ? `血压${rawBloodPressure}mmHg` : ""
        const rawHeartRate = this.model?.characterization?.heartRate
        const heartRate = rawHeartRate ? `心率${rawHeartRate}次/分` : ""
        //content
        const rawArray = [height, weight, neck, bloodPressure, heartRate]
        const contentStr = this.composeContent(rawArray, '，')
        return placeholderText(contentStr)
    }

    diagnosis() {
        return placeholderText(this.model?.diagnosis)
    }

    async parameter() {
        const rawParameter = this.model?.parameter
        let parameterStr = ''
        if (rawParameter) {
            const parameterComposeTool = new ParameterComposeTool(this.model?.parameter)
            const rawParameterArray = await parameterComposeTool.composeHolographyParamArray()
            const parameterArray = rawParameterArray.map((item) => {
                return `${item.title}：${item.info}`
            })
            parameterStr = this.composeContent(parameterArray, '，')
        }
        return placeholderText(parameterStr)
    }

    photoArray() {
        return this.model?.inspectionReport ?? []
    }

    composeContent(rawArray, separator) {
        let strArray = []
        for (const item of rawArray ?? []) {
            if (item) {
                strArray.push(item)
            }
        }
        return strArray.join(separator)
    }

    composeMultipleOptionStr(valueArray, dataSource) {
        let strArray = []
        for (const item of valueArray ?? []) {
            const str = findKey(dataSource, item)
            if (str) {
                strArray.push(str)
            }
        }
        return strArray.join('，')
    }
}