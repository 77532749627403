<template>
    <div class="detail-view">
        <div class="detail-view-item">
            <p class="detail-view-item-title">主诉</p>
            ：
            <p class="detail-view-item-content">{{ displayModel.complaints() }}</p>
        </div>
        <div class="detail-view-item">
            <p class="detail-view-item-title">现病史</p>
            ：
            <p class="detail-view-item-content">{{ displayModel.currentHistory() }}</p>
        </div>
        <div class="detail-view-item">
            <p class="detail-view-item-title">既往史</p>
            ：
            <p class="detail-view-item-content">{{ displayModel.pastHistory() }}</p>
        </div>
        <div class="detail-view-item">
            <p class="detail-view-item-title">个人史</p>
            ：
            <p class="detail-view-item-content">{{ displayModel.personalHistory() }}</p>
        </div>
        <div v-if="isTemporarySaved" class="detail-view-item">
            <p class="detail-view-item-title">体格检查</p>
            ：
            <p class="detail-view-item-content">{{ displayModel.physicalExamination() }}</p>
        </div>
        <div class="detail-view-item">
            <p class="detail-view-item-title">诊断结果</p>
            ：
            <p class="detail-view-item-content">{{ displayModel.diagnosis() }}</p>
        </div>
        <div v-if="isTemporarySaved" class="detail-view-item">
            <p class="detail-view-item-title">治疗参数</p>
            ：
            <p class="detail-view-item-content">{{ parameter }}</p>
        </div>
        <div v-if="isTemporarySaved" class="detail-view-item" style="line-height: 35px;">
            <p class="detail-view-item-title" style="height: 35px; line-height: 35px;">检验报告</p>
            ：
            <div style="height: 100%; width: calc(100% - 75px);">
                <BaselineInfoCardReport :isEdit="false" :reportData="model" :user="{}" @onReportDataChange="() => { }">
                </BaselineInfoCardReport>
            </div>
        </div>

    </div>
</template>

<script>
import { CaseDisplayModel } from "@c/user-main/holography/case-display-model.js";
import BaselineInfoCardReport from '@c/user-main/baseline/baseline-info-card/baseline-info-card-report.vue'

export default {
    components: {
        BaselineInfoCardReport
    },

    props: {
        model: Object,
        isTemporarySaved: Boolean
    },

    data() {
        return {
            parameter: undefined
        }
    },

    async created() {
        this.parameter = await this.displayModel.parameter()
    },

    watch: {
        async displayModel(value) {
            this.parameter = await value.parameter()
        }
    },

    computed: {
        displayModel() {
            return new CaseDisplayModel(this.model)
        },
        imageList() {
            return this.displayModel.photoArray().map(el => ({
                name: el?.categoryName ?? '',
                imageUrl: el?.url ?? null
            }))
        }
    }

}
</script>

<style lang="scss" scoped>
.detail-view {
    display: flex;
    flex-direction: column;

    &-item {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 20px;
        display: flex;

        &-title {
            width: 75px;
            text-align-last: justify;
            text-align: justify;
        }

        &-content {
            flex: 1;
        }

        &-photos {
            display: flex;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 20px;

                &-icon {
                    width: 80px;
                    height: 80px;
                }

                &-title {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
    }
}

/deep/.report-container {
    padding: 0;

    .report-section-title {
        font-size: 18px;
    }

    .link {
        font-size: 18px !important;
    }
}
</style>