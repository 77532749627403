<template>
    <panel-alert class="panel-alert-press-prescription" title="压力处方" width="592px" @onMaskClick="onMaskClick"
        @onCloseClick="onCloseClick">
        <template v-slot:content>
            <parameter-view :model="model"></parameter-view>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import ParameterView from "@c/common/alert/panel-alert/panel-alert-press-prescription/parameter-view.vue";
export default {
    components: {
        PanelAlert,
        ParameterView
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        model: Object,
        isShow: Boolean
    },

    methods: {
        onMaskClick(){
            this.$emit("onIsShowChanged", false);
        },

        onCloseClick() {
            this.$emit("onIsShowChanged", false);
        }
    },
};
</script>
  
<style lang="scss">
.panel-alert-press-prescription {
    .panel-alert-top {
        border-bottom: none;
    }

    .panel-alert-bottom {
        border-top: none;
        padding-right: 24px;

        &-confirm {
            background-color: #DF4747;
        }
    }
}
</style>