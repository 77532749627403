<template>
    <div class="holography">
        <div class="holography-content">
            <holography-list-header @onAddCase="onAddCase"></holography-list-header>
            <div class="holography-content-list" v-for="(item, index) in dataArray" :key="index">
                <holography-list-section-header v-if="index != 0" :title="item.title"></holography-list-section-header>
                <holography-list-cell class="holography-content-list-cell" v-for="(item, index) in item.value" :key="index"
                    :model="item" @onEditCase="onEditCase"></holography-list-cell>
            </div>
            <holography-list-footer class="holography-content-footer" :isEmpty="!dataArray.length"></holography-list-footer>
        </div>
        <!-- 编辑 -->
        <edit-case-alert v-if="isShowEditCaseAlert" v-model="isShowEditCaseAlert" :patientDetailModel="patientDetailModel"
            :initialCaseModel="caseModel" @onEditSucceed="onEditOrAddCaseSucceed">
        </edit-case-alert>
        <!-- 新增 -->
        <edit-case-alert v-show="isShowAddCaseAlert" v-model="isShowAddCaseAlert" :patientDetailModel="patientDetailModel"
            @onEditSucceed="onEditOrAddCaseSucceed">
        </edit-case-alert>
    </div>
</template>

<script>
import HolographyListHeader from "@c/user-main/holography/holography-list-header/holography-list-header.vue";
import HolographyListSectionHeader from "@c/user-main/holography/holography-list-section-heade.vue";
import HolographyListCell from "@c/user-main/holography/holography-list-cell/holography-list-cell.vue";
import HolographyListFooter from "@c/user-main/holography/holography-list-footer.vue";
import { DateTool } from "@js/date-tool.js";
import EventBus from "@js/event-bus.js";
export default {
    components: {
        HolographyListHeader,
        HolographyListSectionHeader,
        HolographyListCell,
        HolographyListFooter,
        EditCaseAlert: () => import("@c/common/pop-view/pop-view-holography-case-edit/index.vue")
    },

    props: {
        patientDetailModel: Object,
    },

    data() {
        return {
            isShowEditCaseAlert: false,
            isShowAddCaseAlert: false,
            dataArray: [],
            caseModel: undefined
        }
    },

    created() {
        this.getCaseList()
    },

    watch: {
        patientDetailModel() {
            this.getCaseList()
        }
    },

    methods: {
        onAddCase() {
            this.isShowAddCaseAlert = true

        },

        onEditCase(model) {
            this.caseModel = model
            this.isShowEditCaseAlert = true
        },

        // EditCaseAlert
        onEditOrAddCaseSucceed() {
            EventBus.$emit("onPatientInfoChanged")
            this.getCaseList()
        },

        async getCaseList() {
            if (!this.patientDetailModel) {
                return;
            }
            try {
                const param = {
                    "pageNum": 1,
                    "pageSize": 100,
                    "patientId": this.patientDetailModel.model.id,
                }
                const response = await this.$api.getCaseList(param)
                this.dataArray = this.composeDataArray(response.list)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        composeDataArray(rawDataArray) {
            const dataArray = rawDataArray.reduce((result, item) => {
                const yearStr = DateTool.timeStrToStr(item.gmtModify, 'YYYY年');
                const currentItem = result.filter((resultItem) => {
                    return resultItem.title == yearStr
                })[0]
                if (!currentItem) {
                    result.push({
                        title: yearStr,
                        value: [item]
                    })
                } else {
                    currentItem.value.push(item)
                }
                return result;
            }, []);
            return dataArray
        }
    }
}
</script>

<style lang="scss" scoped>
.holography {
    box-sizing: border-box;
    padding: 0px 26px 0px 0px;

    &-content {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        &-list {
            display: flex;
            flex-direction: column;

            &-cell {
                width: 100%;
            }
        }

        &-footer {
            flex: 1 1 auto;
            height: 0;
            overflow-y: auto;
        }
    }
}
</style>