<template>
    <pop-view @onMaskClick="onMaskClick">
        <div class="panel-alert-questionnaire">
            <div class="panel-alert-questionnaire-left">
                <navigation-item v-for="item, index in navigationDataArray" :key="index" :title="item" :isLast="index == 1"
                    :isSelected="selectedIndex == index" @onClick="onNavigation(index)"></navigation-item>
            </div>
            <div class="panel-alert-questionnaire-right">
                <content-item v-for="item, index in dataArray" :key="index" :id="item.title" :model="item"></content-item>
            </div>
            <button class="panel-alert-questionnaire-delete" @click="onCancelClick">
                <img class="panel-alert-questionnaire-delete-icon" :src="deleteIcon">
            </button>
        </div>

    </pop-view>
</template>
  
<script>
import PopView from "@c/common/pop-view/pop-view.vue";
import NavigationItem from "./navigation-item.vue";
import ContentItem from "./content-item.vue";

export default {
    components: {
        PopView,
        NavigationItem,
        ContentItem
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        caseModel: Object,
        isShow: Boolean,
        selectedQuestionnaireType: String
    },

    
    data() {
        return {
            deleteIcon: require("@imgs/panel-alert-close.png"),
            navigationDataArray: ["焦虑情绪(GAD-7)", "抑郁情绪(PHQ-9)"],
            selectedIndex: 0,
            dataArray: [],
        }
    },

    async mounted() {
        //dataArray
        await this.getQuestionnaireResultList()
        //selectedIndex
        for (let [index, item] of this.navigationDataArray.entries()) {
            if (item.includes(this.selectedQuestionnaireType)) {
                this.selectedIndex = index
            }
        }
    },

    updated() {
        const itemTitle = this.dataArray[this.selectedIndex].title
        document.getElementById(itemTitle).scrollIntoView({ behavior: "smooth", block: 'start' });
    },

    methods: {
        onMaskClick() {
            this.$emit('onIsShowChanged', false)
        },

        onCancelClick() {
            this.$emit('onIsShowChanged', false)
        },

        onNavigation(index) {
            this.selectedIndex = index
        },

        async getQuestionnaireResultList() {
            try {
                const param = {
                    userId: this.caseModel.patientId
                }
                const rawDataArray = await this.$api.getQuestionnaireResultList(param)
                console.log(rawDataArray)
                this.dataArray = rawDataArray.filter((item)=>{
                   return this.navigationDataArray.indexOf(item.title) != -1
                })
                console.log(this.dataArray)
            } catch (error) {
                this.$toast.showRed(error)
            }
        }
    },
};
</script>
  
<style lang="scss" scoped>
.panel-alert-questionnaire {
    position: relative;
    width: 1278px;
    height: 653px;
    font-size: 20px;
    display: flex;

    &-left {
        border-right: 1px solid #E8E8E8;
        width: 227px;
        height: 100%;
        box-sizing: border-box;
        padding: 20px 0px 0px 23px;

    }

    &-right {
        flex: 1;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &-delete {
        position: absolute;
        width: 44px;
        height: 44px;
        box-sizing: border-box;
        padding: 12px;
        top: 6px;
        right: 0px;

        &-icon {
            width: 100%;
            height: 100%;
        }
    }
}
</style>