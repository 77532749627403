<template>
    <div class="navigation-item" @click="onClick">
        <div class="navigation-item-content">
            <div class="navigation-item-content-circle"></div>
            <p class="navigation-item-content-title" :class="[isSelected ? 'selected' : 'normal']">{{ title }}</p>
        </div>
        <div v-if="!isLast" class="navigation-item-bar"></div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        isLast: Boolean,
        isSelected: Boolean
    },

    methods:{
        onClick(){
            this.$emit("onClick")
        }
    }
}
</script>

<style lang="scss" scoped>
.navigation-item {
    display: flex;
    flex-direction: column;

    &-content {
        display: flex;
        align-items: center;

        &-circle {
            width: 6px;
            height: 6px;
            border-radius: 6px;
            border: 3px solid #2E6BE6;
        }

        &-title {
            font-size: 16px;
            line-height: 24px;
            margin-left: 6px;
        }
    }

    &-bar {
        background-color: #DCDCDC;
        width: 2px;
        height: 40px;
        margin: 2px 0px 2px 4px;
    }
}

.selected {
    color: rgba($color: #000000, $alpha: 0.9);
}

.normal {
    color: #8A8A8A;

}
</style>