<template>
    <div class="holography-list-header">
        <div class="holography-list-header-content">
            <div class="holography-list-header-content-left">
                <div class="holography-list-header-content-left-unit" style="width: 18%">
                    <holography-icon-cell :icon="require('@imgs/holography-diagnose.png')"
                        title="诊断"></holography-icon-cell>
                </div>
                <div class="holography-list-header-content-left-unit" style="width: 18%">
                    <holography-icon-cell :icon="require('@imgs/holography-pressure.png')"
                        title="压力处方"></holography-icon-cell>
                </div>
                <div class="holography-list-header-content-left-unit" style="width: 14%">
                    <holography-icon-cell class="holography-list-header-content-left-unit-report" :icon="require('@imgs/holography-use-report.png')"
                        title="使用报告"></holography-icon-cell>
                </div>
                <div class="holography-list-header-content-left-unit" style="width: 16%">
                    <holography-icon-cell :icon="require('@imgs/holography-test.png')" title="检验检查"></holography-icon-cell>
                </div>
                <div class="holography-list-header-content-left-unit" style="width: 16%">
                    <holography-icon-cell :icon="require('@imgs/holography-evaluate.png')"
                        title="评估"></holography-icon-cell>
                </div>
                <div class="holography-list-header-content-left-unit" style="width: 18%">
                    <holography-icon-cell :icon="require('@imgs/holography-status.png')" title="状态"></holography-icon-cell>
                </div>
            </div>
            <button class="holography-list-header-content-button" @click="onAddCase">新增专科病历</button>
        </div>
    </div>
</template>

<script>
import HolographyIconCell from "@c/user-main/holography/holography-list-header/holography-icon-cell.vue";
export default {
    components: {
        HolographyIconCell,
    },

    methods: {
        onAddCase() {
            this.$emit("onAddCase")
        }
    }
}
</script>

<style lang="scss" scoped>
.holography-list-header {
    box-sizing: border-box;
    padding-left: 9%;

    &-content {
        border-bottom: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-left {
            box-sizing: border-box;
            padding-left: 34px;
            flex: 1;
            display: flex;
            align-items: center;

            &-unit {
                display: flex;
                align-items: center;

                &-report{
                    /deep/.holography-icon-cell-icon{
                        width: 28px;
                    }
                }
            }
        }

        &-button {
            color: #2E6BE6;
            font-size: 14px;
            width: 115px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid #2E6BE6;
        }

    }

}
</style>