<template>
    <div class="unit">
        <p class="unit-title">{{ `${title}：` }}</p>
        <p class="unit-value">{{ value }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String
    }
}
</script>

<style lang="scss" scoped>
.unit {
    font-size: 16px;
    line-height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    &-title {
        width: 82px;
        display: flex;
        justify-content: flex-end;
    }

    &-value {
        margin-right: 12rpx;
    }
}
</style>