<template>
    <div class="holography-breath-drawer-content">
        <page-list-container height="500px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
            @pagination="onPagination">
            <div>
                <list-title-bar></list-title-bar>
                <cell v-for="(item, index) of dataArray" :key="index" :model="item" :isSelected="selectedID === item.id"
                    @onSelectClick="onCellSelectClick" @onCheck="onCheck"></cell>
            </div>
        </page-list-container>
    </div>
</template>

<script>
import PageListContainer from "@c/common/paging-list/paging-list.vue";
import ListTitleBar from "@c/user-main/holography/holography-breath-drawer-content/title-bar.vue";
import Cell from "@c/user-main/holography/holography-breath-drawer-content/cell.vue";
export default {
    components: {
        PageListContainer,
        ListTitleBar,
        Cell,
    },

    props: {
        model: Object
    },

    data() {
        return {
            //列表
            dataArray: [],
            total: 0,
            totalPage: 0,
            currentPage: 1,
            //选中
            selectedID: undefined
        }
    },

    mounted() {
        this.getReportList()
    },

    methods: {
        onPagination: function (currentPage) {
            this.currentPage = currentPage;
            this.getReportList();
        },

        onCellSelectClick(model) {
            this.selectedID = model.id;
        },

        onCheck(itemModel) {
            //跳转报告详情
            let routeData = this.$router.resolve({
                path: "/report-frame",
                query: {
                    uid: this.model.patientId,
                    singleReportID: itemModel.id,
                    hospitalId: this.model.hospitalId
                },
            });
            window.open(routeData.href, "_blank");
        },

        // method
        async getReportList() {
            try {
                const params = {
                    id: this.model.id,
                    pageNum: this.currentPage,
                    pageSize: 10,
                };
                const response = await this.$api.getCaseReportList(params);
                this.dataArray = response.list;
                this.total = response.total;
                this.totalPage = response.pages;
            } catch (error) {
                this.$toast.showRed(error);

            }
        },
    }
}
</script>

<style lang="scss" scoped>
.holography-breath-drawer-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 24px;
    border-top: 1px solid #e8e8e8;

    /deep/.page-list-container {
        height: 100%;

        &-content {
            height: 95% !important;
        }
    }
}
</style>