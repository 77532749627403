<template>
    <div class="holography-list-cell-date-label">
        {{ title }}
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>

<style lang="scss" scoped>
.holography-list-cell-date-label {
    color: white;
    background-color: #2E6BE6;
    font-size: 15px;
    width: 92px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>