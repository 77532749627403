<template>
    <div class="holography-icon-cell">
        <img class="holography-icon-cell-icon" :src="icon">
        <p class="holography-icon-cell-title">{{ title }}</p>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String
    }
}
</script>

<style lang="scss" scoped>
.holography-icon-cell{
    display: flex;
    align-items: center;

    &-icon{
        width: 23px;
    }

    &-title{
        color: #ABC7FF;
        font-size: 16px;
        margin-left: 10px;
    }
}

</style>