export function convertLatitudeStr(str) {
    switch (str) {
        case "1":
            return "①"
        case "2":
            return "②"
        case "3":
            return "③"
        case "4":
            return "④"
        case "5":
            return "⑤"
        case "6":
            return "⑥"
        case "7":
            return "⑦"
        case "8":
            return "⑧"
        case "9":
            return "⑨"
        case "10":
            return "⑩"
        case "11":
            return "⑪"
        case "12":
            return "⑫"
        case "13":
            return "⑬"
        case "14":
            return "⑭"
        case "15":
            return "⑮"
        default:
            return str
    }
}