<template>
    <div class="holography-list-section-header">
        <div class="holography-list-section-header-content">
            <p class="holography-list-section-header-content-label">{{ title }}</p>
            <div class="holography-list-section-header-content-left">
            </div>
        </div>
    </div>
</template>

<script>
export default {


    props: {
        title: String
    }
}
</script>

<style lang="scss" scoped>
.holography-list-section-header {
    box-sizing: border-box;
    padding-left: 9%;


    &-content {
        border-bottom: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-label {
            position: relative;
            left: -46px;
            background: #E8EFFF;
            border-radius: 4px;
            width: 92px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        &-left {
            box-sizing: border-box;
            padding-left: 34px;
            flex: 1;
            display: flex;
            align-items: center;

            &-unit {
                display: flex;
                align-items: center;
            }
        }

        &-button {
            color: #2E6BE6;
            font-size: 14px;
            width: 115px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid #2E6BE6;
        }

    }

}
</style>