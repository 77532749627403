<template>
    <button class="holography-list-cell-status-label" :class="[isPublished ? 'published' : 'saved']" @click="onClick">
        {{ isPublished ? '已发布' : '暂存' }}
    </button>
</template>

<script>
export default {
    props: {
        isPublished: Boolean
    },

    methods: {
        onClick() {
            this.$emit("onClick")
        }
    }
}
</script>

<style lang="scss" scoped>
.holography-list-cell-status-label {
    font-size: 14px;
    height: 29px;
    padding: 0 11px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saved {
    color: #1677FF;
    border: 1px solid #BAE0FF;
    background-color: #E6F4FF;
    width: 53px;
}

.published {
    color: #52C41A;
    border: 1px solid #D9F7BE;
    background-color: #F6FFED;
    width: 68px;

}
</style>