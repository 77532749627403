<template>
    <div class="bar-chart" :id="chartID"></div>
</template>
        
<script>
import ReportSection from "@c/report/report/single-blood-oxygen-report/common/report-section.vue";
import KeyValueUnit from "@c/common/key-value-unit/key-value-unit-blood-oxygen-report.vue";
import { getHexColor } from "@js/common-tool.js";
import { convertLatitudeStr } from "./tool";
export default {
    components: {
        ReportSection,
        KeyValueUnit,
    },

    props: {
        model: Object,
    },

    computed: {
        chartID() {
            return this.model.title + 'Chart' //避讳父组件的id
        }
    },

    mounted() {
        this.chart = this.$echarts.init(document.getElementById(this.chartID));
        this.drawLine();
    },

    methods: {
        drawLine() {
            this.chart.setOption({
                dataset: {
                    source: this.composeSource(),
                },
                grid: {
                    left: 60,
                    top: 30,
                    bottom: 25,
                    right: 0,
                },
                legend: {
                    orient: "horizontal",
                    right: 0,
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        color: getHexColor("rgba(0,0,0,0.6)"),
                        fontSize: 14,
                    },
                    axisLine: {
                        lineStyle: {
                            color: getHexColor("rgba(24,24,24,0.25)"),
                        },
                    },
                },
                yAxis: {
                    max: this.model.userAnswerList[0].maxScore,
                    min: this.model.userAnswerList[0].minScore,
                    splitNumber: this.model.userAnswerList[0].maxScore,
                    margin: 9,
                    axisLabel: {
                        color: getHexColor("rgba(0,0,0,0.4)"),
                        fontSize: 16,
                        padding: [0, 35, 0, 0]
                    },
                    axisLine: {
                        lineStyle: {
                            color: getHexColor("rgba(24,24,24,0.1)"),
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: getHexColor("rgba(24,24,24,0.1)"),
                            width: 2
                        }
                    }
                },
                series: [
                    {
                        type: "bar",
                        barWidth: 38,
                        itemStyle: {
                            color: "#0052D9"
                        },
                    },
                ],
            });
        },

        composeSource() {
            return this.model.userAnswerList.map((item) => {
                return [
                    convertLatitudeStr(item.latitude),
                    item.answerScore
                ]
            })
        },


    },


};
</script>
        
<style lang="scss" scoped>
.bar-chart {
    @include z-index-2;
    width: 100%;
    height: 221px;
    box-sizing: border-box;
}
</style>